var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[_c('v-container',{staticClass:"app-body"},[_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.$t("string.mail"))+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1),(_vm.items.length > 0)?_c('div',{staticClass:"pa-2"},_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticClass:"list-card"},[_c('div',{staticClass:"d-flex align-center px-2"},[_c('div',[_c('div',{staticClass:"text-h6 text-brd-md white--text"},[_vm._v(" "+_vm._s(item.Mail.title)+" ")]),_c('div',{staticClass:"brown--text"},[_vm._v(" "+_vm._s(item.Mail.description)+" ")]),(
                    _vm.$moment(item.Mail.timestampExpired).diff(_vm.$moment()) >= 0
                  )?_c('div',{staticClass:"brown--text text--lighten-2 mt-2"},[_vm._v(" "+_vm._s(_vm.$t("string.expires_in"))+" "+_vm._s(_vm.$moment(item.Mail.timestampExpired).locale(_vm.$_getLocaleForMoment()).fromNow())+" ")]):_c('div',{staticClass:"brown--text text--lighten-2 mt-2"},[_vm._v(" "+_vm._s(_vm.$t("string.expired"))+" ")])])]),_c('div',{staticClass:"d-flex align-center ms-3 me-2"},[(!item.Mail.timestampClaimed)?_c('v-btn',{attrs:{"x-large":"","color":"yellow","disabled":_vm.$moment(item.Mail.timestampExpired).diff(_vm.$moment()) < 0},on:{"click":() => {
                    _vm.$router.push({
                      name: 'PageExploreMailDetail',
                      params: { id: item.Mail.id },
                    });
                  }}},[_vm._v(" "+_vm._s(_vm.$t("string.open"))+" ")]):_c('div',{staticClass:"text-h2 success--text"},[_vm._v(" ✓ ")])],1)])}),0):_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"list-card"},[_c('div',{staticClass:"d-flex align-center px-2"},[_c('div',{staticStyle:{"word-break":"break-all"}},[_c('div',{staticClass:"text-h6 text-brd-md white--text"},[_vm._v(" "+_vm._s(_vm.$t("string.empty"))+" ")]),_c('div',{staticClass:"brown--text"},[_vm._v(" "+_vm._s(_vm.$t("string.empty_msg"))+" ")])])])])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }